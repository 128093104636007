import React from "react";
import ReactGA from 'react-ga';
import {motion} from "framer-motion";
import {DETAIL_ID} from "../models/detail-id.enum";
import {Item} from "../models/item.model";
import "./level-item.scss";

interface LevelItemProps {
    item: Item;
    handleActiveRelatedIds: (ids: DETAIL_ID[]) => void;
    activeRelatedIds: DETAIL_ID[];
    handleActiveId: (id: string | null) => void;
    isMobile: boolean;
    clickedId: string | null;
}

type LevelItemState = {
    isHover: boolean,
    hoverTimeout: any,
    itemClicked: boolean,
}

const DESCRIPTION_VARIANTS = {
    open: {
        maxHeight: 500,
        transition: {
            duration: 1
        }
    },
    closed: {
        maxHeight: 0,
        overflow: "hidden",
        transition: {
            duration: .3
        }
    }
};

class LevelItem extends React.Component<LevelItemProps, LevelItemState> {
    private node: any;

    constructor(props: any) {
        super(props);
        this.state = {
            isHover: false,
            hoverTimeout: 0,
            itemClicked: false,
        };
    };

    componentDidUpdate(prevProps: LevelItemProps): void {
        if (this.props.clickedId !== prevProps.clickedId) {
            this.setState({
                itemClicked: this.props.clickedId === this.props.item.id
            });
        }
    };

    handleMouseEnter = () => {
        const {hoverTimeout} = this.state;

        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
        }

        const timeout = setTimeout(() => {
            this.setState({
                isHover: true,
            });
        }, 100);

        this.setState({
            hoverTimeout: timeout,
        });
    };

    handleMouseLeave = () => {
        const {hoverTimeout} = this.state;

        clearTimeout(hoverTimeout);
        this.setState({
            isHover: false,
        });

        this.props.handleActiveRelatedIds(this.props.activeRelatedIds);
    };

    handleActiveItem = (event: any) => {
        event.preventDefault();
        const {handleActiveId, item, handleActiveRelatedIds} = this.props;

        handleActiveId(item.id);
        handleActiveRelatedIds(item.relatedIds);

        ReactGA.event({
            category: "level-items",
            action: `${item.title}`
        });
    };

    activeItem = () => {
        const {itemClicked, isHover} = this.state;
        const {activeRelatedIds, item} = this.props;

        const isRelated = activeRelatedIds.indexOf(item.id as DETAIL_ID) > -1;
        let className = "level-item";

        if (itemClicked) {
            className += ` level-item__active level-item__active__${this.props.item.type}`;
        }

        if (isHover) {
            className += ` level-item__hovered level-item__hovered__${this.props.item.type}`;
        }

        if (isRelated) {
            className += ` level-item__related level-item__isRelated__${this.props.item.type}`;
        }

        return className;
    };

    render() {
        const {item, isMobile, activeRelatedIds} = this.props;
        const {isHover} = this.state;

        return (
            <>
                <div
                    className={this.activeItem()}
                    ref={node => this.node = node}
                    onClick={this.handleActiveItem}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    <div className={`level-item--category level-item--category__${item.type}`}/>
                    <div
                        className={`level-item--container`}
                    >
                        <div>
                            {item.title}
                        </div>
                    </div>
                </div>

                {isMobile && (
                    item.description || item.relatedIds.length > 1) && (
                    <motion.div
                        className={"level-item-description"}
                        animate={isHover ? "open" : "closed"}
                        variants={DESCRIPTION_VARIANTS}
                    >
                        <div className="level-item-description__description">
                            <p
                                dangerouslySetInnerHTML={{__html: item.description}}
                            />
                            {item.relatedIds.length > 1 &&
                            <p className={"level-item-description__description--relatedIds"}>Related:
                                <span
                                    className={"level-item-description__description--relatedIds__relatedIds"}> {activeRelatedIds.join(", ")} </span>
                            </p>}
                        </div>
                    </motion.div>
                )}
            </>
        )
    };
}

export default LevelItem;
