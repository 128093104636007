import {DETAIL_ID} from "./components/matrix/models/detail-id.enum";
import {SearchMaturityMatrix} from "./components/matrix/models/search-maturity-matrix.model";
import {Type} from "./components/matrix/models/type.enum";

export const SEARCH_MATURITY_MATRIX: SearchMaturityMatrix = {
    title: "Search Maturity Matrix",
    description: "Luminis has a search maturity index that indicates the maturity of search within your organization. We can plot your company on this index using our maturity scan. The search matrix takes another approach; the matrix shows functionalities and actions we expect to be present for each area and maturity level. Interact with the different elements to get an idea about the meaning of them. If you want more information, feel free to give us a call or drop us a line.",
    levels: [
        {
            level: 5,
            items: [
                {
                    id: DETAIL_ID.PERSONALISED_CONTENT,
                    type: Type.CONTENT,
                    title: "Personalised content",
                    description: "You bring your customers the best personalized experience by serving them content specifically tailored to them. The results to search strings should be within the context of the current user.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.SEARCH_DRIVES_BUSINESS,
                    type: Type.ORGANISATION,
                    title: "Search drives business",
                    description: "The search engine is valued as important to increase business value. In case of changes to parts of the site, the impact on search is always considered and measured.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.PERSONALISED_RESULTS,
                    type: Type.UX,
                    title: "Personalised results",
                    description: "You bring your customers the best personalized experience by recommending and serving them content specifically tailored to them.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.MAKE_NEW_STANDARDS,
                    type: Type.UX,
                    title: "Set new standards",
                    description: "Through active development of the user experience, you become a standard creator instead of a standard follower. New ways to show search results give the user that experience that nobody else thought of before.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.SUPPORT_EXPERIMENTS,
                    type: Type.TECHNICAL,
                    title: "Support constant experiments",
                    description: "A lot of companies support A/B tests, often they can only support this in the front-end. You are able to test different versions of the query to see which one is performing better",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.PREDICTABLE_SOLUTION,
                    type: Type.TECHNICAL,
                    title: "Predictable solution",
                    description: "There are no secrets in our technical solution. We know why it behaves the way it does. We know the powers as well as the weaknesses. We can always explain the matching and ordering of search results.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.ANALYTICS_DRIVE_IMPROVEMENTS,
                    type: Type.ANALYTICS,
                    title: "Analytics drive improvements",
                    description: "Every choice for an improvement is coming from something we have measured and whether it is an improvement is measured in an A/B test. Metrics are available to everyone that needs them to make decisions.",
                    relatedIds: []
                },
            ]
        },
        {
            level: 4,
            items: [
                {
                    id: DETAIL_ID.CONTENT_METRICS_ON_DASHBOARD,
                    type: Type.CONTENT,
                    title: "Content metrics on dashboard",
                    description: "A dashboard showing the most important metrics about our content. These can be different for each domain depending on where the content is coming from. Some examples are: number of documents, number of updates, number of errors, overall quality of a batch.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.SEARCH_BOARD,
                    type: Type.ORGANISATION,
                    title: "Search board in place",
                    description: "A dashboard showing the most important search metrics. Think about: most used terms, most used terms without hits, most matched fields, highest conversions terms, lowest conversion terms.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.DASHBOARD_FOR_EVERYBODY,
                    type: Type.ORGANISATION,
                    title: "Dashboard for everybody",
                    description: "As Search becomes vital to the whole company, we need to provide a basic dashboard showing the most important KPIs.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.LEARN_TO_ANSWER_QUESTIONS,
                    type: Type.UX,
                    title: "Learn to answer questions",
                    description: "With voice and chat becoming more important, people don't only search using a few words. They also want to ask questions and get answers instead of documents that might contain the answer.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.MANUAL_EXCEPTIONS,
                    type: Type.UX,
                    title: "Manual exceptions",
                    description: "You can create the best engine, but there will always be exceptions. It is wise to keep the exceptions as close to the search as possible. Being able to add exceptions is a great enhancement to the experience.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.AUTOMATION_ON_QUERIES,
                    type: Type.UX,
                    title: "Automation on queries",
                    description: "Automations are redirects or query results enhancements based on the actual query. When searching for a category, we can redirect to that category. When searching for a non existing or rare term, search for the more common term. Still inform the user about it.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.DATA_PIPELINE,
                    type: Type.TECHNICAL,
                    title: "Data pipeline",
                    description: "As content and other vital information to search like sales and clicks are are increasing, the way to handle them become more important. Using a structured pipeline helps to handle this content effectively and reproducible. ",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.NLP_PIPELINE,
                    type: Type.TECHNICAL,
                    title: "NLP pipeline",
                    description: "For the content as well as the query there are Natural Language Components to extract information from content. Think about extracting entities but also most important keywords in a query. To do this in a pipeline makes it more transparent and effective.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.LEARNING_TO_RANK,
                    type: Type.TECHNICAL,
                    title: "Learning To Rank",
                    description: "One of the problems in search is finding the right order of the search results. You can determine this order by hand, using the search engine, but there is also a way using a machine learned model based on a large number of features coming out of the text and from other data. Using the model based ranking is the domain of Learning To Rank.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.DATA_PIPELINE_ANALYTICS,
                    type: Type.ANALYTICS,
                    title: "Data pipeline analytics",
                    description: "If a data pipeline is available, it is good to have insights into the channel. Be able to monitor the amount of content going through, watch errors, etc.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.MACHINE_LEARNING_ON_EVENTS,
                    type: Type.ANALYTICS,
                    title: "Machine learning on events",
                    description: "Use click events, add to basked events, maybe session tracking to learn from your users. Use machine learning to find groups of specific users. Use the learning context to improve the search results.",
                    relatedIds: []
                },
            ]
        },
        {
            level: 3,
            items: [
                {
                    id: DETAIL_ID.MANUAL_CONTENT_IMPROVEMENTS,
                    type: Type.CONTENT,
                    title: "Manual content improvements",
                    description: "As content keeps flowing in, the quality of the content is often not that great. It is vital to be able to improve the content manually. Preferably without having to do this after each update.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.MUTLI_DISCIPLINARY_TEAM,
                    type: Type.ORGANISATION,
                    title: "Multi disciplinary search team",
                    description: "The team taking care of search should be a combination of different disciplines. Not only technical people. We do need a technical person, but we need a user experience expert, a product owner as representative of the business, a content/domain expert and someone with access to the analytics.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.SPELLING_CORRECTION,
                    type: Type.UX,
                    title: "Spelling correction",
                    description: "In case users make typos, we want to correct them or give suggestions to correct them. Always be transparent about it, tell the user what you did.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.FILTERS_FROM_QUERY,
                    type: Type.UX,
                    title: "Obtain filters from query",
                    description: "In case people search on something that is in fact a category or some other tag or keyword that usually is used as a filter. Present the filter to the user instead of searching on this word. At least clearly give the option to do this is case searching does give other results.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.BOOSTED_ARTICLES,
                    type: Type.UX,
                    title: "Boosted articles",
                    description: "Some articles are more important than others, it is advisable to add article boosting only for a specific amount of time. When adding the option to add a boosted article, make the timespan mandatory.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.LEARN_FROM_USER_SESSION,
                    type: Type.UX,
                    title: "Learn from user session",
                    description: "Track what users are doing, if they improve a query collect the data, this is valuable to improve your search engine. Monitor the amount of clicks someone does before buying something. This tells a lot about the effectiveness of your search engine.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.LEARN_FROM_ANALYTICS,
                    type: Type.TECHNICAL,
                    title: "Learn from analytics",
                    description: "Gathering analytics is nice, but using them is a lot better. Start evaluating the analytics by hand, than create dashboards and do things like automated learning.",
                    relatedIds: [DETAIL_ID.SEARCH_WITHOUT_RESULTS, DETAIL_ID.ANALYTICS_DASHBOARD, DETAIL_ID.MOST_USED_TERMS, DETAIL_ID.AB_TESTING]
                },
                {
                    id: DETAIL_ID.MONITOR_PERFORMANCE,
                    type: Type.TECHNICAL,
                    title: "Monitor performance",
                    description: "Next to monitoring the impact your search solution is making, it's important to keep track of the technical performance as well. As the size of your solution grows, you need to make sure your technical resources can keep up. Monitoring is the first step into getting insights whether you're solution can keep up with the increased traffic.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.AB_TESTING,
                    type: Type.TECHNICAL,
                    title: "A/B testing",
                    description: "A/B Testing is a technique which allows you to test a new version of your solution to a subset of your customers. This allows you to validate new features with actual customers, without having too big losses in case the new feature is not working out as you expected.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.CTR_DASHBOARD,
                    type: Type.ANALYTICS,
                    title: "CTR Dashboard",
                    description: "CTR stands for Click Through Rate. This dashboard will allow you to visualise where your customers are clicking in the result list. A good search engine places the most relevant results on top of the page. This dashboard helps you optimise your result pages for optimal performance.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.ANALYTICS_OVER_TIME,
                    type: Type.ANALYTICS,
                    title: "Analytics over time",
                    description: "Seasonality & Trending Products are just two examples of why monitoring analytics over time is helpful. Some of your products might only be relevant during certain times of the year.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.SEARCH_WITHOUT_RESULTS,
                    type: Type.ANALYTICS,
                    title: "Search without results",
                    description: "This is the first step of improving your search engine. If customers don't have any results for their query, they are not able to buy anything! Keeping track of search terms without results allows you to find the weak spots in your engine and helps improve your customers experience.",
                    relatedIds: []
                },
            ]
        },
        {
            level: 2,
            items: [
                {
                    id: DETAIL_ID.ALL_PAGES_VISIBLE,
                    type: Type.CONTENT,
                    title: "All pages visible",
                    description: "This is a check if all content that comes in is in the end available in the search engine.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.INCONSISTENCIES_VISIBLE,
                    type: Type.CONTENT,
                    title: "Inconsistencies visible",
                    description: "Somehow show a report of products that are different in search than they are in the content. Are we missing categories or products from a specific supplier. Can we find the products that we expect to be found",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.METRICS_DASHBOARD,
                    type: Type.ORGANISATION,
                    title: "Search Metrics Dashboard",
                    description: "A dashboard showing the most important search related metrics like amount of queries, amount of different queries, top queries, etc.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.SPRINT_IMPROVEMENT,
                    type: Type.ORGANISATION,
                    title: "Each sprint an improvement",
                    description: "Improving search is an iterative process as we need user input to tune the search results.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.AUTO_COMPLETE,
                    type: Type.UX,
                    title: "Auto complete",
                    description: "Helping users formulate their query is one of the first steps into understanding user intent. Having an autocomplete feature also reduces the amount of typo's that people can make",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.FILTERS_OR_FACETS,
                    type: Type.UX,
                    title: "Filters or Facets",
                    description: "Give the user a mechanism to filter the results using categories, colors, sizes, or other facets. This is what is called browsing instead of searching.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.IMPROVE_RELAVANCE,
                    type: Type.TECHNICAL,
                    title: "Improve relevance",
                    description: "With the default settings of an engine, search relevance is so-so. By having some expereience now with your engine and by ",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.OPTIMIZE_TECH_DESIGN,
                    type: Type.TECHNICAL,
                    title: "Optimise tech design",
                    description: "After having some experience with your initial setup, it's important to think ahead and start improving the overall architecture.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.TUNE_TECH_SOLUTION,
                    type: Type.TECHNICAL,
                    title: "Tune tech solution",
                    description: "Each technical solution comes with it's own configuration. After the first step of installing the engine, you realise that the default settings are not appropriate for your setup, so tuning this becomes important..",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.MOST_USED_TERMS,
                    type: Type.ANALYTICS,
                    title: "Most used terms",
                    description: "Keeping track of your most used terms allows you to figure out what your customers are coming to you",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.ANALYTICS_DASHBOARD,
                    type: Type.ANALYTICS,
                    title: "Analytics dashboard",
                    description: "An analytics dashboard allows for quick analysis of the current state of search. On this dashboard you can show and keep track your most important KPI's",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.SEARCH_WITHOUT_RESULT,
                    type: Type.ANALYTICS,
                    title: "Search without results",
                    description: "Searches that do not yield any results are important because that is where your users get an empty result page an are not going to be satisfied with the result. Improving 0-hits is the first step to improve your engine.",
                    relatedIds: []
                },
            ]
        },
        {
            level: 1,
            items: [
                {
                    id: DETAIL_ID.CRAWLER,
                    type: Type.CONTENT,
                    title: "Crawler",
                    description: "Getting content for your search engine can be done in multiple different ways. The first step is to crawl content that you want to search through. This doesn't require any ETL or data ingestion layer and is the first step into getting content in your engine.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.DATASTORE_INTEGRATION,
                    type: Type.CONTENT,
                    title: "Datastore integration",
                    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ante pretium, rhoncus dui nec, volutpat felis. Etiam euismod metus non malesuada venenatis. Orci varius natoque penatibus et magnis dis.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.STRUCTURED,
                    type: Type.CONTENT,
                    title: "Structured + ready for search",
                    description: "Content is structured into separate fields which allows for improved queries and allows for more aggregations on your site",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.APPOINT_PERSON,
                    type: Type.ORGANISATION,
                    title: "Appoint person to search",
                    description: "A persron in the organisation is appointed to be the funvtional owner of search. This is impportant so that improvements can be tracked and someone can steer the direction of search form a business perspective.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.INSTALL_TECH,
                    type: Type.TECHNICAL,
                    title: "Install tech solution",
                    description: "The first step to build a search engine is installing a technical solution. This could be Elasticsearch, Solr, pure Lucene or any other search product out there.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.CHOOSE_TECH,
                    type: Type.TECHNICAL,
                    title: "Choose technical direction",
                    description: "Next to choosing a search engine to build on top off, it's important to have a general techincal direction of where to go with the complete solution. Is the frontend going to directly talk to your engine?  Is there a service between your engine and frontend? Etc..",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.UPDATES,
                    type: Type.TECHNICAL,
                    title: "Updates + complete import",
                    description: "You've taken the first steps to keeping your data up-tp-date. By allowing updates anf a complete import you can update your documents whenever necessary.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.PAGINATION,
                    type: Type.UX,
                    title: "Pagination",
                    description: "Pagination can be done in multiple ways. Either by traditional pages that a user can navigate between",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.SEARCH_RESULTS,
                    type: Type.UX,
                    title: "Search results with image",
                    description: "Users are more inclined to buy a product when they can see what the product looks like. By adding images to your result page, you're starting to improve the experience of the user!",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.SEARCH_BOX,
                    type: Type.UX,
                    title: "Search box",
                    description: "There is a search box available where users can start to find the products/information they are looking for",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.NUMBER_OF_DOCUMENTS,
                    type: Type.ANALYTICS,
                    title: "Number of documents",
                    description: "It's important to keep track of the amount of documents in the index, so it becomes easier to spot errors with the creation of indices.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.SLOW_QUERIES,
                    type: Type.ANALYTICS,
                    title: "Slow queries",
                    description: "If it takes too long for your solution to reply to a customer's query, he/she might decide to leave your site and go to your competitor. Monitoring slow queries gives you the ability to prevent these cases and take action when needed.",
                    relatedIds: []
                },
                {
                    id: DETAIL_ID.DISK_SPACE,
                    type: Type.ANALYTICS,
                    title: "Disk space",
                    description: "Running out of disk space is an issues we have seen a lot in our experience with search. After installing the solution some engineers let it run for weeks/months without monitoring key components. Running out of disk space will c",
                    relatedIds: []
                },
            ]
        }
    ]
};
