import React from "react";
import "./matrix-level.scss";
import { SEARCH_MATURITY_MATRIX } from "../../../data";
import LevelItem from "../level-item/level-item";
import { DETAIL_ID } from "../models/detail-id.enum";
import { Type } from "../models/type.enum";
import { Accordion, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

type MatrixLevelProps = {
  level: number;
  isMobile: boolean;
  handleActiveId: (id: string | null) => void;
  handleActiveRelatedIds: (activeRelatedIds: DETAIL_ID[]) => void;
  activeRelatedIds: DETAIL_ID[] | null;
  clickedId: string | null;
};

const MatrixLevel = ({
  level,
  isMobile,
  handleActiveRelatedIds,
  handleActiveId,
  activeRelatedIds,
  clickedId,
}: MatrixLevelProps) => {
  const getLevelItems = (requiredLevel: number, itemType: Type): any => {
    const filteredLevel = SEARCH_MATURITY_MATRIX.levels.filter(
      (level) => level.level === requiredLevel
    )[0];

    return filteredLevel.items
      .filter((item) => item.type === itemType)
      .map((item: any, index: number) => (
        <div key={index} className={`levelItems_${requiredLevel}`}>
          <LevelItem
            item={item}
            handleActiveRelatedIds={handleActiveRelatedIds}
            activeRelatedIds={activeRelatedIds || []}
            handleActiveId={handleActiveId}
            isMobile={isMobile}
            clickedId={clickedId}
          />
        </div>
      ));
  };

  if (isMobile) {
    return (
      <Accordion defaultActiveKey="1">
        <Card>
          <Accordion.Item
            as={Card.Header}
            eventKey={`${level}`}
            className={`matrix-level--row__level level_${level}`}
          >
            Level {level}
          </Accordion.Item>
          <Accordion.Collapse eventKey={`${level}`}>
            <Card.Body>
              <div className={"matrix-level--row"}>
                {isMobile && (
                  <div className={"matrix-level--row__category"}>
                    Organisation
                  </div>
                )}
                {getLevelItems(level, Type.ORGANISATION)}
              </div>
              <div className={"matrix-level--row"}>
                {isMobile && (
                  <div className={"matrix-level--row__category"}>Content</div>
                )}
                {getLevelItems(level, Type.CONTENT)}
              </div>
              <div className={"matrix-level--row"}>
                {isMobile && (
                  <div className={"matrix-level--row__category "}>
                    Analytics
                  </div>
                )}
                {getLevelItems(level, Type.ANALYTICS)}
              </div>
              <div className={"matrix-level--row"}>
                {isMobile && (
                  <div className={"matrix-level--row__category "}>
                    Technical
                  </div>
                )}
                {getLevelItems(level, Type.TECHNICAL)}
              </div>
              <div className={"matrix-level--row"}>
                {isMobile && (
                  <div className={"matrix-level--row__category "}>
                    User Experience
                  </div>
                )}
                {getLevelItems(level, Type.UX)}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
  return (
    <div className={"matrix-level--container"}>
      <div className={`matrix-level--row__level level_${level}`}>{level}</div>
      <div className={"matrix-level--row"}>
        {getLevelItems(level, Type.ORGANISATION)}
      </div>
      <div className={"matrix-level--row"}>
        {getLevelItems(level, Type.CONTENT)}
      </div>
      <div className={"matrix-level--row"}>
        {getLevelItems(level, Type.ANALYTICS)}
      </div>
      <div className={"matrix-level--row"}>
        {getLevelItems(level, Type.TECHNICAL)}
      </div>
      <div className={"matrix-level--row"}>{getLevelItems(level, Type.UX)}</div>
    </div>
  );
};

export default MatrixLevel;
