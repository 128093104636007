import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import "./description.scss";
import { Item } from "../matrix/models/item.model";
import { SEARCH_MATURITY_MATRIX } from "../../data";
const LuminisLogo = require("../../images/luminislogo.png");

interface DescriptionProps {
  item: Item | null;
  isMobile: boolean;
  hasDescription: boolean;
  handleCloseDescription: (clickedClose: boolean) => void;
  relatedIds: string | null;
}

interface DescriptionState {
  closeDescription: boolean;
}

class Description extends React.Component<DescriptionProps, DescriptionState> {
  constructor(props: any) {
    super(props);
    this.state = {
      closeDescription: false,
    };
  }

  handleDescription = (description: boolean) => {
    if (description) {
      this.setState({
        closeDescription: true,
      });

      this.props.handleCloseDescription(true);
    } else {
      this.setState({
        closeDescription: false,
      });
    }

    return description;
  };

  render() {
    const { item, isMobile, hasDescription, relatedIds } = this.props;

    return (
      <div className={"description"}>
        <h2 className={"description--title"}>
          {!isMobile && item
            ? `${item.title}`
            : `${SEARCH_MATURITY_MATRIX.title}`}
        </h2>
        <div className={"description--text"}>
          <p>
            {!isMobile && item
              ? `${item.description}`
              : `${SEARCH_MATURITY_MATRIX.description}`}
          </p>

          {!isMobile && item && item.relatedIds.length > 1 && (
            <p className={"description--text--relatedIds"}>
              Related:{" "}
              <span className={"description--relatedIds"}>{relatedIds} </span>
            </p>
          )}
        </div>
        {!isMobile && hasDescription && (
          <p
            onClick={() => this.handleDescription(hasDescription)}
            className={"description--close-button"}
          >
            close
          </p>
        )}

        <div className={"description--footer"}>
          <img
            className={"description--logo"}
            src={LuminisLogo}
            alt="Luminis logo"
          />
          <div className="description--footer__contact">
            <div>
              <FontAwesomeIcon
                icon={faPhone}
                size="xs"
                className="footer__icon"
              />
              <a href="tel:+31885864670">088 586 4670</a>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faEnvelope}
                size="xs"
                className="footer__icon"
              />
              <a href="mailto:info.amsterdam@luminis.eu">
                info.amsterdam@luminis.eu
              </a>
            </div>
          </div>
          <div className="footer__about">
            <div>
              <a
                href="https://www.luminis.eu/about-luminis/"
                target="_blank"
                rel="noopener noreferrer"
              >
                About Luminis
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Description;
