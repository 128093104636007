import React from "react";
import "./page404.scss";
import { Link } from "react-router-dom";
const LuminisLogo = require("../../images/luminislogo.png");

const Page404 = () => (
  <div className={"notFound"}>
    <div className={"notFound--container"}>
      <h2 className="notFound--title">Sorry. Page not found</h2>
      <div className={"notFound--text"}>
        <p>Sorry this page is not found.</p>
        <p>
          Please try again later, or click{" "}
          <Link to="/">
            <u>here</u>
          </Link>{" "}
          to go back to the home page.
        </p>
        <h5 className="notFound--subTitle">Need to contact us?</h5>
        <p>
          You can call us on <a href="tel:+31885864670">088 586 4670</a> or send
          us an email at{" "}
          <a href="mailto:info.amsterdam@luminis.eu">
            info.amsterdam@luminis.eu
          </a>
        </p>
      </div>
      <img className={"notFound--logo"} src={LuminisLogo} alt="Luminis logo" />
    </div>
  </div>
);

export default Page404;
