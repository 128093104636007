import React from "react";
import MatrixLevel from "./matrix-level/matrix-level";
import "./matrix.scss";
import {DETAIL_ID} from "./models/detail-id.enum";

interface MatrixProps {
    handleActiveId: (id: string | null) => void;
    isMobile: boolean;
    clickedId: string | null;
    handleActiveRelatedIds: (ids: DETAIL_ID[]) => void;
    activeRelatedIds: DETAIL_ID[] | null;
}

type Matrixstate = {
    itemId: string,
    showCategory: boolean
}

class Matrix extends React.Component<MatrixProps, Matrixstate> {
    constructor(props: any) {
        super(props);
        this.state = {
            itemId: "",
            showCategory: false,
        };
    }

    render() {
        const {isMobile, handleActiveId, clickedId, handleActiveRelatedIds, activeRelatedIds} = this.props;

        return (
            <div className="matrix">
                <div className={"matrix--table"}>

                    <MatrixLevel
                        level={5}
                        isMobile={isMobile}
                        handleActiveRelatedIds={handleActiveRelatedIds}
                        activeRelatedIds={activeRelatedIds}
                        clickedId={clickedId}
                        handleActiveId={handleActiveId}
                    />
                    <MatrixLevel
                        level={4} isMobile={isMobile}
                        handleActiveRelatedIds={handleActiveRelatedIds}
                        activeRelatedIds={activeRelatedIds}
                        clickedId={clickedId}
                        handleActiveId={handleActiveId}
                    />
                    <MatrixLevel
                        level={3}isMobile={isMobile}
                        handleActiveRelatedIds={handleActiveRelatedIds}
                        activeRelatedIds={activeRelatedIds}
                        clickedId={clickedId}
                        handleActiveId={handleActiveId}
                    />
                    <MatrixLevel
                        level={2}isMobile={isMobile}
                        handleActiveRelatedIds={handleActiveRelatedIds}
                        activeRelatedIds={activeRelatedIds}
                        clickedId={clickedId}
                        handleActiveId={handleActiveId}
                    />
                    <MatrixLevel
                        level={1}isMobile={isMobile}
                        handleActiveRelatedIds={handleActiveRelatedIds}
                        activeRelatedIds={activeRelatedIds}
                        clickedId={clickedId}
                        handleActiveId={handleActiveId}
                    />

                    {!isMobile && (
                        <div className={"matrix--row__categories"}>
                            <div>Organisation</div>
                            <div>Content</div>
                            <div>Analytics</div>
                            <div>Technical</div>
                            <div>User Experience</div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default Matrix;
