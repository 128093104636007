import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Roadmap from "./components/roadmap/roadmap";
import Page404 from "./components/page404/page404";

const App = () => {
  return (
    <BrowserRouter>
      <div className="app">
        <Routes>
          <Route path="/" element={<Roadmap />} />
          <Route element={<Page404 />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
