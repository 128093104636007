import React from "react";
import Description from "../description/description";
import { SEARCH_MATURITY_MATRIX } from "../../data";
import Matrix from "../matrix/matrix";
import "./roadmap.scss";
import { Item } from "../matrix/models/item.model";
import { DETAIL_ID } from "../matrix/models/detail-id.enum";

type RoadmapState = {
  screenWidth: number;
  clickedId: string | null;
  hasDescription: boolean;
  clickedClose: boolean;
  activeRelatedIds: DETAIL_ID[] | null;
  relatedIds: string | null;
};

class Roadmap extends React.Component<{}, RoadmapState> {
  constructor(props: any) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
      clickedId: null,
      hasDescription: false,
      clickedClose: false,
      activeRelatedIds: null,
      relatedIds: null,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  handleActiveId = (id: string | null) => {
    this.setState({
      clickedId: id,
      hasDescription: true,
    });
  };

  handleCloseDescription = (clickedClose: boolean) => {
    this.setState({
      clickedClose: clickedClose,
    });

    if (clickedClose) {
      this.setState({
        activeRelatedIds: [],
      });
    }

    if (clickedClose) {
      this.setState({
        clickedId: null,
        hasDescription: false,
        clickedClose: false,
      });
    }
  };

  handleActiveRelatedIds = (activeRelatedIds: DETAIL_ID[] | null) => {
    this.setState({
      activeRelatedIds: activeRelatedIds,
    });
  };

  handleRelatedIds = (item: Item | null) => {
    const allItems = SEARCH_MATURITY_MATRIX.levels.flatMap(
      (level) => level.items
    );

    const relatedIds =
      item && item.relatedIds.length > 1 ? item.relatedIds : [];

    const filteredRelatedIds = allItems
      .filter((item: any) => {
        return relatedIds.indexOf(item.id) > -1;
      })
      .map((item) => item.title);

    return filteredRelatedIds.join(", ");
  };

  render() {
    const { screenWidth, clickedId, hasDescription, activeRelatedIds } =
      this.state;
    const isMobile = screenWidth <= 500;

    return (
      <>
        <h1 className={"roadmap--title"}>Search Matrix</h1>
        <div className={"roadmap"}>
          <Matrix
            handleActiveId={this.handleActiveId}
            isMobile={isMobile}
            clickedId={clickedId}
            handleActiveRelatedIds={this.handleActiveRelatedIds}
            activeRelatedIds={activeRelatedIds}
          />

          {clickedId === null ? (
            <Description
              item={null}
              isMobile={isMobile}
              hasDescription={hasDescription}
              handleCloseDescription={this.handleCloseDescription}
              relatedIds={null}
            />
          ) : (
            SEARCH_MATURITY_MATRIX.levels.map((level) =>
              level.items
                .filter((item) => item.id === clickedId)
                .map((item, index) => (
                  <Description
                    key={index}
                    item={item}
                    isMobile={isMobile}
                    hasDescription={hasDescription}
                    handleCloseDescription={this.handleCloseDescription}
                    relatedIds={this.handleRelatedIds(item)}
                  />
                ))
            )
          )}
        </div>
      </>
    );
  }
}

export default Roadmap;
