export enum DETAIL_ID {
    DISK_SPACE = "disk-space",
    SLOW_QUERIES = "slow-queries",
    NUMBER_OF_DOCUMENTS = "number-of-documents",
    SEARCH_BOX = "search-box",
    SEARCH_RESULTS = "search-results",
    PAGINATION = "pagination",
    UPDATES = "updates",
    CHOOSE_TECH = "choose-tech",
    INSTALL_TECH = "install-tech",
    APPOINT_PERSON = "appoint-person",
    STRUCTURED = "structured",
    DATASTORE_INTEGRATION = "datastore-integration",
    CRAWLER = "crawler",
    ALL_PAGES_VISIBLE = "all-pages-visible",
    INCONSISTENCIES_VISIBLE = "inconsistencies-visible",
    METRICS_DASHBOARD = "metrics-dashboard",
    SPRINT_IMPROVEMENT = "sprint-improvement",
    AUTO_COMPLETE = "auto-complete",
    FILTERS_OR_FACETS = "filters-or-facets",
    IMPROVE_RELAVANCE = "improve-relavance",
    OPTIMIZE_TECH_DESIGN = "optimize-tech-design",
    TUNE_TECH_SOLUTION = "tune-tech-solution",
    MOST_USED_TERMS = "most-used-terms",
    ANALYTICS_DASHBOARD = "analytics-dashboard",
    SEARCH_WITHOUT_RESULT = "search-without-result",
    MANUAL_CONTENT_IMPROVEMENTS = "manual-content-improvements",
    MUTLI_DISCIPLINARY_TEAM = "multi-disciplinary-team",
    SPELLING_CORRECTION = "spelling-correction",
    FILTERS_FROM_QUERY = "filters-from-query",
    BOOSTED_ARTICLES = "boosted-articles",
    LEARN_FROM_USER_SESSION = "learn-from-user-session",
    LEARN_FROM_ANALYTICS = "learn-from-analytics",
    MONITOR_PERFORMANCE = "monitor-performance",
    AB_TESTING = "ab-testing",
    CTR_DASHBOARD = "ctr-dashboard",
    ANALYTICS_OVER_TIME = "analytics-over-time",
    SEARCH_WITHOUT_RESULTS = "search-without-results",
    CONTENT_METRICS_ON_DASHBOARD = "content-metrics-on-dashboard",
    SEARCH_BOARD = "search-board",
    DASHBOARD_FOR_EVERYBODY = "dashboard-for-everybody",
    LEARN_TO_ANSWER_QUESTIONS = "learn-to-answer-questions",
    MANUAL_EXCEPTIONS = "manual-exceptions",
    AUTOMATION_ON_QUERIES = "automation-on-queries",
    DATA_PIPELINE = "data-pipeline",
    NLP_PIPELINE = "nlp-pipeline",
    LEARNING_TO_RANK = "learning-to-rank",
    DATA_PIPELINE_ANALYTICS = "data-pipeline-analytics",
    MACHINE_LEARNING_ON_EVENTS = "machine-learning-on-events",
    PERSONALISED_CONTENT = "personalised-content",
    SEARCH_DRIVES_BUSINESS = "search-drives-business",
    PERSONALISED_RESULTS = "personalised-results",
    MAKE_NEW_STANDARDS = "make-new-standards",
    SUPPORT_EXPERIMENTS = "support-experiments",
    PREDICTABLE_SOLUTION = "predictable-solution",
    ANALYTICS_DRIVE_IMPROVEMENTS = "analytics-drive-improvements",
}
